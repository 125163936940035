/*------------------------------------------------------------------
Policy Wordings
 ------------------------------------------------------------------*/
import $    from 'jquery';
import _    from 'lodash';
import Noty from 'noty';
import swal from 'sweetalert2';

let $body     = $('body');
let downloads = [];
let current;

/*------------------------------------------------------------------
Noty Overrides
------------------------------------------------------------------*/

Noty.overrideDefaults({ layout: 'bottomRight', theme: 'metroui', timeout: 666, animation: { open: 'animated bounceInRight', close: 'animated bounceOutRight' } });

const templateDownloadsItem = (download) => {
    return `<a  target="_blank" href="${download.file_link}" class="downloads__wrap__grid__item [ js-item-to-download ]" data-id="${download.file_id}">
                <div class="downloads__wrap__grid__item__title">${download.file_title}</div>
                 <div class="rightAlign [ js-remove-item-from-list ]" data-id="${download.id}">
                    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g id="SVGs" transform="translate(-380.000000, -135.000000)" fill="#5F6062" fill-rule="nonzero"><polygon id="--copy" points="388.997547 142.583333 395.06688 148.652667 393.652667 150.06688 387.583333 143.997547 381.514 150.06688 380.099787 148.652667 386.16912 142.583333 380.099787 136.514 381.514 135.099787 387.583333 141.16912 393.652667 135.099787 395.06688 136.514"></polygon></g></g></svg>
                    <span>REMOVE</span>
                </div>
                <div class="rightAlign">
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="21" viewBox="0 0 26 21" class="svg-download-document"><path d="M23.86-.008l2.127 2.115-13 13.075L-.015 2.107 2.113-.008l10.873 10.935L23.86-.008zM1.697 20.038v-3h22.576v3H1.698z" fill="#54575A" fill-rule="nonzero"/></svg>
                    <span>DOWNLOAD</span>
                </div>
                </a>
            </div>`;
};

const templateDownloadItem = (download) => {
    return `<div class="downloads__wrap__grid__item" data-id="${download.code}">
                <p class="downloads__wrap__grid__item__title">${download.file_title}</p>
                <a href="#" target="_blank" class="downloads__wrap__grid__item__downloadAlt [ js-add-to-downloads ]" data-id="${download.code}">
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="16" viewBox="0 0 26 16" class="svg-download-document-alt"><path d="M23.874 0L26 2.115 13 15.19 0 2.115 2.127 0 13 10.935z" fill="#54575A" fill-rule="nonzero"/></svg>
                    <span>ADD TO DOWNLOAD LIST</span>
                </a>
            </div>`;
};

$body.on('click', '.js-item-to-download', function (e) {
    window.dataLayer.push({
        'event'         : 'ga_event',
        'event_category': 'Content Interaction',
        'event_action'  : 'Download',  // the current page path where the user has interacted with some content block
        'event_label'   : $(this).find('.downloads__wrap__grid__item__title').text() // the name of the content block
    });

});

$body.on('click', '#Form_PolicyForm_action_Submit', function (e) {
    e.preventDefault();
    filterDownloadsJson(window.policies, $('#Form_PolicyForm_Code').val().trim());
});

function filterDownloadsJson(policies, query) {
    $body.addClass('loading');

    window.dataLayer.push({
        'event'         : 'ga_event',
        'event_category': 'Policy Documents',
        'event_action'  : 'Search Item',  // the current page path where the user has interacted with some content block
        'event_label'   : query // the name of the content block
    });
    $.ajax({
        url     : $('.policySearchForm').attr('data-api-url'),
        dataType: 'json',
        type    : 'post',
        data    : { 'id': query }
    }).done((response) => {
        setTimeout(() => $body.removeClass('loading'), 600);
        if (response.data instanceof Array !== true) {
            current = response.data;
            renderDownloadList(response.data);
        } else {
            current = undefined;
            //new Noty({ type: 'error', text: response.message }).show();

            swal({
                type             : 'warning',
                title            : 'Whoops!',
                html             : response.message,
                confirmButtonText: 'OK',
            });
        }
    });
}

function renderDownloadList(document) {
    $('.js-download-list').empty();
    $('.js-download-list').append(templateDownloadItem(document));
}

function renderDownloadsList() {
    $('.js-downloads-all').show();
    $('.js-downloads-list').empty();
    if (downloads && downloads.length) {
        _.each(downloads, (download) => {
            $('.js-downloads-list').append(templateDownloadsItem(download));
        });
    } else {
        $('.js-downloads-all').hide();
    }
}

$body.on('click', '.js-add-to-downloads', function (e) {
    e.preventDefault();
    downloads.push(current);
    renderDownloadsList();
});

$body.on('click', '.js-download-all-files', function (e) {
    e.preventDefault();
    let arr = [];
    $('.js-item-to-download').each(function () {
        arr.push($(this).attr('data-id'))
    });

    window.open(`${$('.js-download-all-files').attr('data-api-url')}${arr.join(',')}`);

    window.dataLayer.push({
        'event'         : 'ga_event',
        'event_category': 'Policy Documents',
        'event_action'  : 'Download',  // the current page path where the user has interacted with some content block
        'event_label'   : '(not set)' // the name of the content block
    });

    $.ajax({
        url     : $('.js-download-all-files').attr('data-api-url'),
        dataType: 'json',
        type    : 'post',
        data    : { 'files': arr.join(',') }
    }).done((response) => {
        console.log(response)
    });
});

$body.on('click', '.js-remove-item-from-list', function (e) {
    e.preventDefault();
    let $this = $(this);
    _.remove(downloads, (download) => {
        return download.id === parseInt($this.attr('data-id'));
    });
    renderDownloadsList();

});

// MDNZI0514
// TMCHU0616