let mapStyles = [
    {
        "featureType": "all",
        "elementType": "labels",
        "stylers"    : [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "landscape.man_made",
        "elementType": "geometry",
        "stylers"    : [
            {
                "weight": 0.9
            },
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry.fill",
        "stylers"    : [
            {
                "visibility": "off"
            },
            {
                "color": "#83cead"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels",
        "stylers"    : [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers"    : [
            {
                "visibility": "off"
            },
            {
                "color": "#fee379"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "all",
        "stylers"    : [
            {"visibility": "off"}
        ]
    },
    {
        featureType: "administrative.country",
        elementType: "geometry.stroke",
        stylers    : [
            {"visibility": "off"}
        ]
    },
    {
        featureType: "administrative",
        elementType: "labels",
        stylers    : [
            {"visibility": "off"}
        ]
    }, {
        featureType: "poi",
        elementType: "labels",
        stylers    : [
            {visibility: "off"}
        ]
    }, {
        featureType: "water",
        elementType: "labels",
        stylers    : [
            {visibility: "off"}
        ]
    }, {
        featureType: "road",
        elementType: "labels",
        stylers    : [
            {visibility: "off"}
        ]
    },
    {
        "featureType": "road",
        "elementType": "all",
        "stylers"    : [
            {
                "visibility": "off"
            },
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        featureType: 'water',
        elementType: 'geometry',
        stylers    : [{color: '#aadaff'}]
    },
    {
        "featureType": "landscape",
        "elementType": "all",
        "stylers"    : [{color: "#afb81c"}]
    }
];

export default mapStyles;