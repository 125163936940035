/*------------------------------------------------------------------
Imports
------------------------------------------------------------------*/

import $       from 'jquery';
import _       from 'lodash';
import select2 from "select2";

select2($);
/*------------------------------------------------------------------
Variables
------------------------------------------------------------------*/

let $searchBlockInput = $('.ctaBlock #SearchForm_SearchForm_Search');

/*------------------------------------------------------------------
Search Block
------------------------------------------------------------------*/

//$searchBlockInput.attr('placeholder', 'Broker\'s name or branch location').val('');

let $brokerSearch = $('.js-broker-search');

if ($brokerSearch.length) {
    let regions  = [];
    let branches = [];
    let people   = [];

    $brokerSearch.select2({ width: '100%', placeholder: `Broker's name or location` });

    $.getJSON($brokerSearch.attr('data-api-url'), (response) => {
        let brokers = response.data;
        _.each(brokers, (broker) => {
            regions.push(broker.region);
            branches.push({ title: broker.title, region: broker.region });
            _.each(broker.brokers, (person) => {
                people.push(person);
            });
        });

        regions  = _.uniq(regions);
        branches = _.uniq(branches);

        _.each(branches, (branch) => {
            $brokerSearch.append(`<option value="?branch=${branch.title}">${branch.title}, ${branch.region}</option>`);
        });

        _.each(regions, (region) => {
            $brokerSearch.append(`<option value="?region=${region}">${region}</option>`);
        });

        _.each(people, (person) => {
            $brokerSearch.append(`<option value="?person=${person.id}">${person.title}, ${person.job_title}, ${person.branch}</option>`);
        });

    });
}

$brokerSearch.on('change', function (e) {
    e.preventDefault();
    if ($(this).val() !== '-1') {
        window.location = `${$(this).attr('data-target-url')}${encodeURI($(this).val())}`;
    }
});
