/*------------------------------------------------------------------
Imports
------------------------------------------------------------------*/

import $ from 'jquery';
import 'slick-carousel';
/*------------------------------------------------------------------
Variables
------------------------------------------------------------------*/

let $searchBlockInput  = $('.footer #SearchForm_SearchForm_Search');
let $searchBlockSubmit = $('.footer #SearchForm_SearchForm_action_results');

/*------------------------------------------------------------------
Search Block
------------------------------------------------------------------*/

$searchBlockInput.attr('placeholder', 'Your email').val('');
$searchBlockSubmit.val('SUBMIT');

let $slider = $('.js-sponsor-slider');

function slickify() {
    if ($slider.hasClass('active')) {
        if ($('.sponsors__tab__item').length > 4) {
            $slider.slick({
                slidesToShow : 4,
                arrows       : true,
                autoplay     : true,
                autoplaySpeed: 2000,
                infinite     : true,
                prevArrow    : `<button class="sponsors__tab__prev"><svg width="22" height="41" viewBox="0 0 22 41" xmlns="http://www.w3.org/2000/svg"><path d="M20.442 0L22 1.562 3.115 20.5 22 39.438 20.442 41 0 20.5" fill="#A9C3D6" fill-rule="evenodd"/></svg></button>`,
                nextArrow    : `<button class="sponsors__tab__next"><svg width="22" height="41" viewBox="0 0 22 41" xmlns="http://www.w3.org/2000/svg"><path d="M1.558 0L0 1.562 18.885 20.5 0 39.438 1.558 41 22 20.5" fill="#A9C3D6" fill-rule="evenodd"/></svg></button>`,
                responsive   : [
                    { breakpoint: 1024, settings: { slidesToShow: 3 } },
                    { breakpoint: 700, settings: { slidesToShow: 2 } },
                    { breakpoint: 480, settings: { slidesToShow: 1 } }
                ]
            });
        }
    } else if ($slider.hasClass('slick-initialized')) {
        $slider.slick('unslick');
    }
}

$().ready(function () {
    slickify();

    $(window).resize(function () {
        let $windowWidth = $(window).width();
        if ($windowWidth > 480) {
            slickify();
        }
    });

});

