/*------------------------------------------------------------------
Imports
------------------------------------------------------------------*/

import $         from 'jquery';
import TweenLite from 'gsap';

/*------------------------------------------------------------------
Variables
------------------------------------------------------------------*/

let $body           = $('body');
let $toggleNavItems = $('.js-toggle-nav-item');
let $navItems       = $('.js-nav-item');

/*------------------------------------------------------------------
Navburger
------------------------------------------------------------------*/

hamburger('.js-toggle-navigation', '.header');

function hamburger(hamburger, nav) {
    let $nav               = $(nav),
        $hamburger         = $(hamburger),
        $lineOne           = $hamburger.find('span:first-of-type'),
        $lineTwo           = $hamburger.find('span:nth-child(2)'),
        $lineThree         = $hamburger.find('span:last-of-type'),
        hamburgerAnimation = new TimelineMax({ paused: true }),
        canClick           = 1;

    hamburgerAnimation
        .to([$lineOne, $lineThree], 0.2, { top: "50%" }, 'start')
        .set($lineTwo, { autoAlpha: 1 }, 'start')
        .set($lineTwo, { autoAlpha: 0 }, 'mid')
        .to($lineOne, 0.2, { rotation: 45 }, 'end')
        .to($lineThree, 0.2, { rotation: -45 }, 'end');

    $hamburger.on('click', function () {
        if (canClick) {
            canClick = 0;
            if (!$hamburger.hasClass('open')) {
                hamburgerAnimation.restart();
                $hamburger.addClass('open');
                $body.addClass('noScrolling');
                $body.addClass('navigationActive')
            } else {
                hamburgerAnimation.reverse();
                $hamburger.removeClass('open');
                $nav.removeClass('open');
                $body.removeClass('noScrolling');
                $body.removeClass('navigationActive')
            }
            setTimeout(function () {
                canClick = 1;
            }, 500);
        }
    })
}

/*------------------------------------------------------------------
Toggle navigation items
------------------------------------------------------------------*/

$body.on('click', '.js-toggle-nav-item', function (e) {
    e.preventDefault();
    let $this = $(this);
    if (!$this.closest('.mobile__wrap__menu__item').hasClass('active')) {
        $toggleNavItems.not(this).each(function () {
            $(this).closest('.mobile__wrap__menu__item').removeClass('active');
            $(this).closest('.mobile__wrap__menu__item').find('.js-nav-item').slideUp(300);
        });
        setTimeout(() => {
            $this.closest('.mobile__wrap__menu__item').addClass('active');
            $this.closest('.mobile__wrap__menu__item').find('.js-nav-item').slideDown();
        }, 300)
    } else {
        $toggleNavItems.each(function () {
            $(this).closest('.mobile__wrap__menu__item').removeClass('active');
            $(this).closest('.mobile__wrap__menu__item').find('.js-nav-item').slideUp(300);
        });
    }
});

$body.on('click', '.js-toggle-sub-nav', function (e) {
    e.preventDefault();
    let $this = $(this);
    let id    = $this.attr('data-id');
    $('.js-toggle-sub-nav').removeClass('active');
    $(this).addClass('active');
    $('.js-sub-nav-target').hide();
    $(`[data-id="${id}"]`).show();
});


