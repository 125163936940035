import $     from "jquery";
import slick from 'slick-carousel';

/*------------------------------------------------------------------
Home Page Slider
------------------------------------------------------------------*/

let $tabbedContentSlider = $('.js-tabbed-slider');

if ($tabbedContentSlider.length) {
    $tabbedContentSlider.slick({
        dots        : true,
        arrows      : true,
        infinite    : true,
        slidesToShow: 3,
        prevArrow   : `<a href="#" class="slider__wrap__control slider__wrap__control--prev"><svg width="32" height="57" xmlns="http://www.w3.org/2000/svg"><path d="M31.102 54.95l-2.14 2.1L.93 28.5 28.96-.05l2.141 2.1L5.134 28.5z" fill="#A7A7A7" fill-rule="nonzero"/></svg></a>`,
        nextArrow   : `<a href="#" class="slider__wrap__control slider__wrap__control--next"><svg width="32" height="57" xmlns="http://www.w3.org/2000/svg"><path d="M.93 2.05l2.14-2.1L31.102 28.5 3.07 57.05l-2.14-2.1L26.898 28.5z" fill="#A7A7A7" fill-rule="nonzero"/></svg></a>`,
        responsive  : [
            { breakpoint: 1440, settings: { slidesToShow: 3 } },
            { breakpoint: 768, settings: { slidesToShow: 2 } },
            { breakpoint: 640, settings: { slidesToShow: 1 } }
        ]
    });
}
