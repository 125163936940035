import $ from 'jquery';

window.dotq = window.dotq || [];

const dotQ = function(action){
    window.dotq.push({
        'projectId': '10000',
        'properties': {
            'pixelId': '10085804',
            'qstrings': {
                'et': 'custom',
                'ea':`{${action}}`
            }
        }
    });
}

$('form').each(function(){
    $(this).on('submit', function(){
        dotQ('formsubmission');
    })
});

$('a[href^="tel:"]').each(function(){
    $(this).on('click', function(){
        dotQ('clicktocall');
    })
});

$('#brokerSearch').on('change', function(){
    dotQ('findabroker');
});