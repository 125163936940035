/*------------------------------------------------------------------
Imports
------------------------------------------------------------------*/

import $       from 'jquery';
import AOS     from 'aos';
import tingle  from 'tingle.js';
import Noty    from 'noty';
import parsley from 'parsleyjs';
import slick   from 'slick-carousel';
import swal    from 'sweetalert2';
import 'array-from-polyfill';

require('es6-object-assign').polyfill();
import './mobileNav';
import './maps';
import './searchPane';
import './searchBlock';
import './tabbedContent';
import './footerNewsletter';
import './brokerSearch';
import './enquiryLogic';
import './tabbedContentSlider';
import './accordions';
import './newsHolder';
import './search';
import './splitAccordions';
import './policyWordings';
import './dotq';

window.jQuery = $;

Noty.overrideDefaults({
    layout   : 'bottomRight',
    theme    : 'metroui',
    timeout  : 2000,
    animation: {
        open : 'animated bounceInRight',
        close: 'animated bounceOutRight'
    }
});

/*------------------------------------------------------------------
Variables
------------------------------------------------------------------*/

let $body = $('body');

/*------------------------------------------------------------------
AOS
------------------------------------------------------------------*/

function msieversion() {
    let ua   = window.navigator.userAgent;
    let msie = ua.indexOf('MSIE');
    if (msie > 0) {
        $('[data-aos^=fade][data-aos^=fade]').css('opacity', '1');
    } else {
        AOS.init();
    }
}

$(document).ready(function () {
    setTimeout(() => {
        msieversion();
        $body.addClass('loaded');
    }, 20);
});

/*------------------------------------------------------------------
Home Page Slider
------------------------------------------------------------------*/

let $homeSlider = $('.js-slider');

if ($homeSlider.length) {
    $homeSlider.slick({
        dots        : true,
        arrows      : false,
        infinite    : true,
        fade        : true,
        slidesToShow: 1
    });
}

import select2 from "select2";
import _       from "lodash";

select2($);

$('.js-select,.field.dropdown select').select2({ width: '100%' });

$('#quotecriteria_form select').each(function () {
    let $this = $(this);
    $this.select2({ width: '100%' });
    $this.on('select2:select', function (e) {
        $this.trigger('change');
        if ($this.val() === '2') {
            $('#adult_age2').show();
        } else {
            $('#adult_age2').hide();

        }

    });
});

/*------------------------------------------------------------------
 Youtube Modals
 ------------------------------------------------------------------*/

$body.on('click', '.js-video-modal', function (e) {
    e.preventDefault();
    let modal = new tingle.modal({
        cssClass: ['videoModal'],
        onClose() {
            modal.destroy();
        },
        onOpen() {
            $('.tingle-modal__close').html(`<div class="closeIcon"><svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" class="svg-close-large-green"><title>close-large-green</title><path fill="#B0B91E" fill-rule="evenodd" d="M76.1463415,300 L42,265.853659 L47.8536585,260 L82,294.146341 L116.146341,260 L122,265.853659 L87.8536585,300 L122,334.146341 L116.146341,340 L82,305.853659 L47.8536585,340 L42,334.146341 L76.1463415,300 Z" transform="translate(-42 -260)"></path></svg></div>`);
            $('.modal').addClass('active');
        }
    });
    if ($(this).attr('data-video-source') === 'Vimeo') {
        modal.setContent(`<div class="modal__close"><a href="#" class="modal__close__icon [ js-close-modal ]"><svg xmlns="http://www.w3.org/2000/svg" width="86" height="85" viewBox="0 0 86 85" class="svg-close"><polygon fill="none" stroke="#704C99" stroke-width="3.78" points="840 88.29 824.516 73 799.999 97.207 775.484 73 760 88.29 784.515 112.499 760 136.708 775.484 152 799.999 127.789 824.516 152 840 136.708 815.483 112.499" transform="translate(-757 -70)"></polygon></svg></a></div><div class="modal wysiwyg"><div class="embed-container"><iframe src="https://player.vimeo.com/video/${$(this).attr('data-video-id')}?title=0&byline=0&portrait=0&autoplay=1" width="640" height="360" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe></div></div>`);
    } else {
        modal.setContent(`<div class="modal__close"><a href="#" class="modal__close__icon [ js-close-modal ]"><svg xmlns="http://www.w3.org/2000/svg" width="86" height="85" viewBox="0 0 86 85" class="svg-close"><polygon fill="none" stroke="#704C99" stroke-width="3.78" points="840 88.29 824.516 73 799.999 97.207 775.484 73 760 88.29 784.515 112.499 760 136.708 775.484 152 799.999 127.789 824.516 152 840 136.708 815.483 112.499" transform="translate(-757 -70)"></polygon></svg></a></div><div class="modal wysiwyg"><div class="embed-container"><iframe width="560" height="315" src="https://www.youtube.com/embed/${$(this).attr('data-video-id')}?autoplay=1&showinfo=0&rel=0" frameborder="0" allowfullscreen></iframe></div></div>`);
    }
    setTimeout(() => modal.open(), 300);

    $body.on('click', '.js-close-modal', function (e) {
        e.preventDefault();
        modal.close();
    });

    $(document).keydown(function (e) {
        if (e.keyCode === 27) {
            modal.close();
        }
    });

});

/*------------------------------------------------------------------
Tables
------------------------------------------------------------------*/

$('.block table').each(function () {
    $(this).wrap('<div class="responsiveTable"></div>')
});

/*------------------------------------------------------------------
Contact Form
 ------------------------------------------------------------------*/

const $SubscriptionForm = $('#SubscriptionForm_SubscriptionForm');

if ($SubscriptionForm.length) {
    $SubscriptionForm.parsley({
        classHandler: function (el) {
            return el.$element.closest('.input-wrap');
        }
    }).on('form:submit', () => {
        $.ajax({
            url : $SubscriptionForm.attr('action'),
            type: 'POST',
            data: $SubscriptionForm.serialize()
        }).done(function (response) {
            let data = JSON.parse(response);
            new Noty({ type: 'success', text: `${data.message}` }).show();
            $SubscriptionForm[0].reset();

            if (data.status !== 'error') {
                window.dataLayer.push({
                    'event'         : 'ga_event',
                    'event_category': 'Newsletter Subscribe',
                    'event_action'  : window.location.pathname,            // the current page path where the user has subscribed to the newsletter
                    'event_label'   : '(not set)'
                });
            }

        }).fail(function (response) {
            let data = JSON.parse(response);
            new Noty({ type: 'error', text: `${data.message}` }).show();
        });
        return false;
    });
}

/*------------------------------------------------------------------
User Defined Form
 ------------------------------------------------------------------*/

const $userDefinedForm = $('form.userform');
$userDefinedForm.on('click', '.action[type="submit"]', function (e) {
    e.preventDefault();
    $userDefinedForm.submit();
});

if ($userDefinedForm.length) {
    $userDefinedForm.parsley({
        classHandler: function (el) {
            return el.$element.closest('.input-wrap');
        }
    }).on('form:submit', () => {
        $.ajax({
            url : $userDefinedForm.attr('action'),
            type: 'POST',
            data: $userDefinedForm.serialize()
        }).done(function (response) {
            let data = JSON.parse(response);
            // new Noty({ type: 'success', text: `${data.message}` }).show();
            swal({
                type             : 'success',
                title            : 'Success!',
                html             : data.message,
                confirmButtonText: 'OK',
            });
            $userDefinedForm[0].reset();
        }).fail(function (response) {
            let data = JSON.parse(response);
            // new Noty({ type: 'error', text: `${data.message}` }).show();
            swal({
                type             : 'error',
                title            : 'Whoops!',
                html             : data.message,
                confirmButtonText: 'OK',
            });
        });
        return false;
    });
}
//window.claimforms

const templateClaim = (data) => {
    return `  <div class="downloads__wrap__grid__item" data-segments="{$SegmentIDs}">
                    <p class="downloads__wrap__grid__item__title">${data.title}</p>
                    <a href="${data.file_link}" target="_blank" class="downloads__wrap__grid__item__view">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" class="svg-view-document"><path d="M14.123 8.833c0-2.947-2.379-5.333-5.311-5.333C5.879 3.5 3.5 5.886 3.5 8.833c0 2.946 2.379 5.332 5.312 5.332 2.932 0 5.311-2.386 5.311-5.332zm1.934 4.085l5.346 5.361-2.125 2.118-5.146-5.162a8.265 8.265 0 0 1-5.32 1.93C4.22 17.165.5 13.434.5 8.833.5 4.232 4.22.5 8.812.5c4.591 0 8.311 3.732 8.311 8.333a8.311 8.311 0 0 1-1.066 4.085z" fill="#54575A" fill-rule="nonzero"/></svg>
                        <span>VIEW</span>
                    </a>
                    <a href="${data.download_link}" target="_blank" class="downloads__wrap__grid__item__download">
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="21" viewBox="0 0 26 21" class="svg-download-document"><path d="M23.86-.008l2.127 2.115-13 13.075L-.015 2.107 2.113-.008l10.873 10.935L23.86-.008zM1.697 20.038v-3h22.576v3H1.698z" fill="#54575A" fill-rule="nonzero"/></svg>
                        <span>DOWNLOAD</span>
                    </a>
                </div>`;
};

function renderClaimList(forms) {
    $('.js-claims-grid').empty();
    if (forms && forms.length) {
        _.each(forms, (download) => {
            $('.js-claims-grid').append(templateClaim(download));
        });
    } else {

    }
}

$body.on('click', '.js-claims-filter', function (e) {
    e.preventDefault();
    let filter = parseInt($(this).attr('data-id'));
    if (filter === -1) {
        renderClaimList(window.claimforms);
    } else {
        let filteredClaims = _.filter(window.claimforms, (form) => {
            return form.segments.indexOf(filter) > -1;
        });
        renderClaimList(filteredClaims);
    }
});

$body.on('click', '.submit-quotecriteria', function (e) {
    window.dataLayer.push({
        'event'         : 'ga_event',
        'event_category': 'Travel Insurance',
        'event_action'  : 'Form Submit',
        'event_label'   : '(not set)'
    });
});

if (window.location.hash === '#paymonthly') {
    $('.js-change-form-choice').eq(1).trigger('click');
    $('html, body').animate({scrollTop:$('#PaymentForm_PaymentForm').offset().top - 50});
}