/*------------------------------------------------------------------
Imports
------------------------------------------------------------------*/

import $ from 'jquery';

/*------------------------------------------------------------------
Variables
------------------------------------------------------------------*/

let $searchBlockInput = $('.searchBlock #SearchForm_SearchForm_Search');

/*------------------------------------------------------------------
Search Block
------------------------------------------------------------------*/

$searchBlockInput.attr('placeholder', 'I am looking for...').val('');
