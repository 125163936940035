import $ from "jquery";

/*------------------------------------------------------------------
Split Accordions
------------------------------------------------------------------*/

let $body                  = $('body');
let $splitAccordion        = $('.js-split-accordion');
let $splitAccordionContent = $('.js-split-accordion-content');

$body.on('click', '.js-split-accordion', function (e) {
    e.preventDefault();
    let $this = $(this);

    window.dataLayer.push({
        'event'         : 'ga_event',
        'event_category': 'Content Interaction',
        'event_action'  : window.location.pathname,  // the current page path where the user has interacted with some content block
        'event_label'   : $(this).find('.splitAccordion__flex__column__item__header__title').text() // the name of the content block
    });

    if ($this.hasClass('active')) {
        $splitAccordion.removeClass('active');
        $splitAccordionContent.slideUp();
    } else {
        $splitAccordion.removeClass('active');
        $splitAccordionContent.slideUp();
        $this.addClass('active');
        $this.next('.js-split-accordion-content').slideDown();
    }

});
