/*------------------------------------------------------------------
Imports
------------------------------------------------------------------*/

import $ from 'jquery';

/*------------------------------------------------------------------
Variables
------------------------------------------------------------------*/

let $body = $('body');

/*------------------------------------------------------------------
Search Pane
------------------------------------------------------------------*/

let $triggers = $('.js-trigger-tabbed-content');

$body.on('click', '.js-trigger-tabbed-content', function (e) {
    e.preventDefault();
    let $this = $(this);
    if (!$this.hasClass('selected')) {
        $triggers.removeClass('selected');
        $this.addClass('selected');
    }
});
