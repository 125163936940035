/*------------------------------------------------------------------
Imports
------------------------------------------------------------------*/

import $ from 'jquery';

/*------------------------------------------------------------------
Variables
------------------------------------------------------------------*/

let $body            = $('body');
let $searchPaneInput = $('.searchPane #SearchForm_SearchForm_Search');

/*------------------------------------------------------------------
Search Pane
------------------------------------------------------------------*/

$searchPaneInput.attr('placeholder', 'How can we help you...?').val('');

$body.on('click', '.js-toggle-search-pane', function (e) {
    e.preventDefault();
    $body.addClass('searchActive');
    setTimeout(() => $searchPaneInput.focus(), 100);
});

$body.on('click', '.js-close-search-pane', function (e) {
    e.preventDefault();
    $body.removeClass('searchActive');
});

$(document).keydown(function (e) {
    if (e.keyCode === 27) {
        $body.removeClass('searchActive');
    }
});