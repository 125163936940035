/*------------------------------------------------------------------
Masonry Layout - Search
 ------------------------------------------------------------------*/
import $       from 'jquery';
import Shuffle from 'shufflejs';
import 'array-from-polyfill';
require('es6-object-assign').polyfill();
let newsShuffleInstance;
let $newsFilter           = $('.js-filter-news');
let $currentNewsFilter    = $('.js-filter-current');
let $newsFilterToggle     = $('.js-filter-toggle');
let $newsFilterExpandable = $('.js-filter-expandable');

if ($('.js-news-masonry').length) {
    newsShuffleInstance = new Shuffle($('.js-news-masonry')[0], {
        itemSelector: '.js-news-masonry-item',
        sizer       : '.js-news-item-sizer',
        buffer      : 1,
    });

    setTimeout(() => newsShuffleInstance.update(), 500);
    setTimeout(() => newsShuffleInstance.update(), 1000);
    setTimeout(() => newsShuffleInstance.update(), 1500);
}

$newsFilter.click(function (e) {
    e.preventDefault();
    $newsFilter.removeClass('selected');
    $(this).addClass('selected');
    filterSearchItems($(this));
});

$newsFilterToggle.click(function (e) {
    e.preventDefault();
    $newsFilterExpandable.slideToggle();
});

function filterSearchItems($el) {
    $newsFilter.removeClass('active');
    $el.addClass('active');
    $currentNewsFilter.text($el.attr('data-filter').toUpperCase());
    if ($el.attr('data-filter') === 'all') {
        newsShuffleInstance.filter()
    } else {
        newsShuffleInstance.filter(function (element) {
            return element.getAttribute('data-filter').indexOf($el.attr('data-filter')) > -1;
        });
    }
}

let newsFilterIndicatorResizeTimer;

$(window).on('resize', function (e) {
    clearTimeout(newsFilterIndicatorResizeTimer);
    newsFilterIndicatorResizeTimer = setTimeout(function () {
        if ($('.js-filter.active').length) {
        }
    }, 250);
});
