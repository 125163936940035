/*------------------------------------------------------------------
Masonry Layout - Search
 ------------------------------------------------------------------*/
import $       from 'jquery';
import Shuffle from 'shufflejs';

let shuffleInstance;
let $filter           = $('.js-filter-search');
let $currentFilter    = $('.js-filter-current');
let $filterToggle     = $('.js-filter-toggle');
let $filterExpandable = $('.js-filter-expandable');

if ($('.js-masonry').length) {
    shuffleInstance = new Shuffle($('.js-masonry')[0], {
        itemSelector: '.js-masonry-item',
        sizer       : '.js-item-sizer',
        buffer      : 1,
    });

    setTimeout(() => shuffleInstance.update(), 500);
    setTimeout(() => shuffleInstance.update(), 1000);
    setTimeout(() => shuffleInstance.update(), 1500);
}

$filter.click(function (e) {
    e.preventDefault();
    $filter.removeClass('selected');
    $(this).addClass('selected');
    filterSearchItems($(this));
});

$filterToggle.click(function (e) {
    e.preventDefault();
    $filterExpandable.slideToggle();
});

function filterSearchItems($el) {
    $filter.removeClass('active');
    $el.addClass('active');
    $currentFilter.text($el.attr('data-filter').toUpperCase());
    if ($el.attr('data-filter') === 'all') {
        shuffleInstance.filter()
    } else {
        shuffleInstance.filter(function (element) {
            return element.getAttribute('data-filter') === $el.attr('data-filter');
        });
    }
}

let filterIndicatorResizeTimer;

$(window).on('resize', function (e) {
    clearTimeout(filterIndicatorResizeTimer);
    filterIndicatorResizeTimer = setTimeout(function () {
        if ($('.js-filter.active').length) {
        }
    }, 250);
});
