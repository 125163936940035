import $ from "jquery";

/*------------------------------------------------------------------
Split Accordions
------------------------------------------------------------------*/

let $body             = $('body');
let $accordion        = $('.js-accordion');
let $accordionContent = $('.js-accordion-content');

$body.on('click', '.js-trigger-accordion', function (e) {
    e.preventDefault();
    let $this = $(this);

    window.dataLayer.push({
        'event'         : 'ga_event',
        'event_category': 'Content Interaction',
        'event_action'  : window.location.pathname,  // the current page path where the user has interacted with some content block
        'event_label'   : $(this).find('h6').text() // the name of the content block
    });

    if ($this.parent().hasClass('active')) {
        $accordion.removeClass('active');
        $accordionContent.slideUp();
    } else {
        $accordion.removeClass('active');
        $accordionContent.slideUp();
        $this.parent().addClass('active');
        $this.next('.js-accordion-content').slideDown();
    }

});
